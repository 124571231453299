@font-face {
  font-family: "JoystixMonospace-Regular";
  src: url("styles/joystix.woff2") format("woff2"), url("styles/joystix.woff") format("woff"),
    url("styles/joystix.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "JoystixMonospace-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}
